<template>
  <!-- 水印组件 -->
  <div class="Watermark" ref="watermark">
    <div class="wap" ref="wap">
      <div v-for="item in numberOfChildren" :key="item" class="'child'" :ref="'child' + item">
        {{ UserInfo.UserName }}
      </div>
    </div>
  </div>
</template>
<script>
import { Decrypt } from "@/scripts/utils";
export default {
  data() {
    return {
      numberOfChildren: 3,
      ConfigInfo: window.Config,
      UserInfo: {}
    };
  },
  mounted() {
    this.UserInfo = JSON.parse(Decrypt(localStorage.getItem("UserInfo")));
    // console.log("配置信息", this.ConfigInfo);
    // console.log("用户信息", this.UserInfo);
    this.setPosition();
    this.createObserver();
  },
  watch: {},
  methods: {
    setPosition() {
      const width = this.$refs.watermark.offsetWidth;
      const height = this.$refs.watermark.offsetHeight;
      //  对角线
      const diagonalLength = Math.round(Math.sqrt(width ** 2 + height ** 2));
      //  旋转角度
      const rotationAngle = (Math.atan2(height, width) * 180) / Math.PI;
      switch (this.ConfigInfo.WatermarkPosition) {
        case "top":
          this.$refs.wap.style.width = `100%`;
          this.$refs.wap.style.top = `0`;
          for (let i = 1; i <= this.numberOfChildren; i++) {
            const name = "child" + i;
            this.$refs[name].style.transform = `rotate(-${this.ConfigInfo.WatermarkRotate}deg)`;
          }
          break;
        case "left":
          this.$refs.wap.style.height = `100%`;
          this.$refs.wap.style.left = `0`;
          this.$refs.wap.style.flexDirection = `column`;
          for (let i = 1; i <= this.numberOfChildren; i++) {
            const name = "child" + i;
            this.$refs[name].style.transform = `rotate(-${this.ConfigInfo.WatermarkRotate}deg)`;
          }
          break;
        case "bottom":
          this.$refs.wap.style.width = `100%`;
          this.$refs.wap.style.bottom = `0`;
          for (let i = 1; i <= this.numberOfChildren; i++) {
            const name = "child" + i;
            this.$refs[name].style.transform = `rotate(-${this.ConfigInfo.WatermarkRotate}deg)`;
          }
          break;
        case "right":
          this.$refs.wap.style.height = `100%`;
          this.$refs.wap.style.right = `0`;
          this.$refs.wap.style.flexDirection = `column`;
          for (let i = 1; i <= this.numberOfChildren; i++) {
            const name = "child" + i;
            this.$refs[name].style.transform = `rotate(-${this.ConfigInfo.WatermarkRotate}deg)`;
          }
          break;
        case "center":
          this.$refs.wap.style.width = `100%`;
          this.$refs.wap.style.height = `100%`;
          this.$refs.wap.style.alignItems = `center`;
          for (let i = 1; i <= this.numberOfChildren; i++) {
            const name = "child" + i;
            this.$refs[name].style.transform = `rotate(-${this.ConfigInfo.WatermarkRotate}deg)`;
          }
          break;
        case "oblique":
          this.$refs.wap.style.width = `${diagonalLength}px`;
          this.$refs.wap.style.transform = `rotate(${rotationAngle}deg)`;
          for (let i = 1; i <= this.numberOfChildren; i++) {
            const name = "child" + i;
            console.log("domdomdomdom", this.$refs[name]);
            this.$refs[name].style.transform = `rotate(-${rotationAngle + this.ConfigInfo.WatermarkRotate}deg)`;
          }
          break;
        default:
          this.$refs.wap.style.width = `${diagonalLength}px`;
          this.$refs.wap.style.transform = `rotate(${rotationAngle}deg)`;
          for (let i = 1; i <= this.numberOfChildren; i++) {
            const name = "child" + i;
            this.$refs[name].style.transform = `rotate(-${rotationAngle + this.ConfigInfo.WatermarkRotate}deg)`;
          }
          break;
      }
      this.$refs.wap.style.color = `rgba(255,255,255,${this.ConfigInfo.WatermarkTransparency})`;
      this.$refs.wap.style.fontSize = `${this.ConfigInfo.WatermarkSize}px`;
    },
    // 监听盒子的尺寸变化
    createObserver() {
      const dom = this.$refs.watermark; // 假设this.$refs.target返回是VueComponent对象
      this.observer = new ResizeObserver(this.setPosition);
      this.observer.observe(dom);
    },
    // 移除监听盒子事件
    removeObserver() {
      this.observer.disconnect();
    }
  },
  beforeUnmount() {
    this.removeObserver();
  }
};
</script>
<style lang="less" scoped>
.Watermark {
  position: absolute;
  width: 100%;
  height: 100%;
  color: #fff;
  overflow: hidden;
  pointer-events: none;
  .wap {
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    z-index: 998;
    transform-origin: 4% 50%; /* 设置变换原点为左上角 */
    user-select: none;
    pointer-events: none;
  }
  .child {
    transform: rotate(45deg);
    pointer-events: none;
  }
}
</style>
