<template>
  <div class="player-wrap">
    <van-loading class="state-load" color="#ffffff" v-if="showLoading" size="28" />
    <div ref="jesVideoElement" class="jes-player">
      <MyWatermark v-if="isJX && ConfigInfo?.JXWatermark && !ConfigInfo?.Watermark" />

      <!-- 静音和全屏 -->
      <div class="player-control" v-if="!showLoading">
        <img
          :class="isFullScreen ? 'full-size' : ''"
          src="../../assets/img/mute.png"
          v-if="muted"
          @click="PlayMuted(false)"
        />
        <img
          :class="isFullScreen ? 'full-size' : ''"
          src="../../assets/img/un_mute.png"
          v-else
          @click="PlayMuted(true)"
        />
        <img src="../../assets/img/full_screen.png" v-if="!isFullScreen" alt="" @click="fullScreenChange(true)" />
        <img
          :class="isFullScreen ? 'full-size' : ''"
          src="../../assets/img/un_full_screen.png"
          v-else
          alt=""
          @click="fullScreenChange(false)"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import JessibucaPro from "@/scripts/jessibuca/jessibuca-pro.js";
import { Decrypt } from "@/scripts/utils";
import { GetWaterMark } from "@/scripts/waterMark";
import MyWatermark from "@/components/Watermark.vue";
const { watermark } = GetWaterMark();
export default {
  components: {
    MyWatermark
  },
  data() {
    return {
      jesPlayer: "",
      jesVideoElement: null,
      showLoading: true,
      muted: true,
      isFullScreen: false,
      ConfigInfo: window.Config,
      isJX: false
    };
  },

  props: {
    /** 播放路径 */
    playPath: String,
    isMuted: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    playPath(newVal, oldVal) {
      // 切换流之前，判断之前的流是否销毁
      // this.DestoryJes();
      if (this.jesPlayer) {
        this.jesPlayer
          .destroy()
          .then(() => {
            this.jesPlayer = null;
            this.CreateJesPlayer();
            this.showLoading = true;
          })
          .catch(() => {
            console.log("destroy失败");
          });
      } else {
        this.CreateJesPlayer();
      }
    }
  },
  mounted() {
    this.CreateJesPlayer();
    //江夏学院首页功能列表调整
    if (localStorage.getItem("OrgToken")) {
      const org = Decrypt(localStorage.getItem("OrgToken"));
      if (window.Config.OrgList.includes(org)) {
        this.isJX = true;
      }
    }
  },
  beforeUnmount() {
    this.DestoryJes();
  },
  methods: {
    CreateJesPlayer() {
      if (this.$refs.jesVideoElement) {
        this.jesPlayer = new window.JessibucaPro({
          container: this.$refs.jesVideoElement,
          decoder: "/wasms/decoder-pro-simd.js",
          timeout: 5,
          heartTimeoutReplayTimes: -1,
          loadingTimeoutReplayTimes: -1,
          videoBuffer: 1, // 设置最大缓冲时长，单位秒，播放器会自动消除延迟。
          videoBufferDelay: 1000,
          isResize: true, //当为true的时候：视频画面做等比缩放后,高或宽对齐canvas区域,画面不被拉伸,但有黑边。 等同于 setScaleMode(1)当为false的时候：视频画面完全填充canvas区域,画面会被拉伸。等同于 setScaleMode(0)
          loadingText: "", //加载过程中文案。
          debug: false,
          useMSE: true,
          useWCS: false,
          autoWasm: true,
          isNotMute: false, //是否开启声音，默认是关闭声音播放的。
          debugLevel: "debug",
          // useSIMD: false,
          // useCanvasRender: true,
          // useVideoRender: false,
          openWebglAlignment: true
        });

        if (this.playPath) {
          this.jesPlayer
            .play(this.playPath)
            .then(() => {
              console.log("播放成功");
              this.showLoading = false;
              this.applyWatermark();
            })
            .catch((error) => {
              console.log("播放异常", error);
            });
          this.doMuted(this.isMuted);
        }

        this.jesPlayer.on("playFailedAndPaused", (error) => {
          // 这里统一的做重连播放。
          console.log("playFailedAndPaused", error);
          this.jesPlayer
            .destroy()
            .then(() => {
              console.log("正在重连", this.playPath);
              this.showLoading = true;
              this.jesPlayer = null;
              this.CreateJesPlayer();
            })
            .catch(() => {
              console.log("destroy失败");
            });
        });
      }
    },
    PlayMuted(mute) {
      this.muted = mute;
      this.doMuted(mute);
    },
    fullScreenChange(flag) {
      this.isFullScreen = flag;
      if (this.jesPlayer) {
        this.jesPlayer.setFullscreen(flag);
      }
    },
    doMuted(mute) {
      if (this.jesPlayer) {
        if (mute) {
          this.jesPlayer.mute();
        } else {
          this.jesPlayer.cancelMute();
        }
      }
    },
    DestoryJes() {
      if (this.jesPlayer) {
        this.jesPlayer.close();
        this.jesPlayer.destroy();
      }
    },
    // 添加水印
    applyWatermark() {
      const username = JSON.parse(Decrypt(localStorage.getItem("UserInfo"))).UserName;
      const watermarkId = Math.random();
      if (this.ConfigInfo.Watermark) {
        watermark(
          username,
          this.$refs.jesVideoElement,
          `rgba(255,255,255,${this.ConfigInfo.WatermarkTransparency})`,
          watermarkId
        );
      }
    }
  }
};
</script>

<style lang="less">
.player-wrap {
  width: 100%;
  height: 100%;
  .state-load {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -14px;
    margin-left: -14px;
    z-index: 10;
  }
  .player-control {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 11;
    img {
      width: 25px;
      height: 25px;
      &:nth-child(1) {
        margin-right: 10px;
      }
    }
    .full-size {
      width: 14px;
      height: 14px;
    }
  }
}
.jes-player {
  color: #fff;
  width: 100%;
  height: 100%;
  position: relative;
  video {
    width: 100%;
    height: 100%;
    background: black;
  }
}

.jessibuca-loading {
  display: none !important;
}
</style>
