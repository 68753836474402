export const GetWaterMark = () => {
    /**
     *
     * @param {要设置的水印的内容} str
     * @param {需要设置水印的容器} container
     */
    const setWatermark = (str: string, container: any, fillColor: string, id: string) => {
        // 唯一id
        //   const id = Math.random();

        //
        if (container === "" || container === undefined) {
            // 如何不传值 默认以body作为水印底板
            container = document.body;
        }
        // 查看页面上有没有，如果有则删除
        if (document.getElementById(id) !== null) {
            const childelement = document.getElementById(id) as any;
            childelement.parentNode.removeChild(childelement);
        }

        const containerWidth = container.offsetWidth; // 获取父容器宽
        const containerHeight = container.offsetHeight; // 获取父容器高再加上一个固定高度
        container.style.position = "relative"; // 设置布局为相对布局

        // 创建canvas元素(先制作一块背景图)
        const can = document.createElement("canvas") as HTMLCanvasElement;
        // 获取屏幕宽度的一半
        const minwidth = 150;
        const minheight = 100;
        can.width = minwidth; // 设置每一块的宽度
        can.height = minheight; // 高度
        const cans = can.getContext("2d") as any; // 获取canvas画布
        cans.rotate((-40 * Math.PI) / 180); // 逆时针旋转π/9
        cans.font = "14px Vedana"; // 设置字体

        cans.fillStyle = fillColor;
        cans.textAlign = "left"; // 文本对齐方式
        cans.textBaseline = "middle"; // 文本基线
        cans.fillText(str, -30, (4 * can.height) / 4); // 绘制文字

        // 创建一个div元素
        const div = document.createElement("div");
        div.id = id; // 设置id
        div.style.pointerEvents = "none"; // 取消所有事件
        div.style.top = "0px";
        div.style.left = "0px";
        div.style.position = "absolute";
        div.style.zIndex = "100000";
        div.style.width = containerWidth + "px";
        div.style.height = containerHeight + "px";
        div.style.background =
            "url(" + can.toDataURL("image/png") + ") left top repeat";
        container.appendChild(div); // 追加到页面

        return id;
    };

    // 该方法只允许调用一次
    const watermark = (str: string, container: any, fillColor: string, id: string) => {
        setWatermark(str, container, fillColor, id);
        // 监听页面大小的变化
        window.onresize = () => {
            setWatermark(str, container, fillColor, id);
        };
    };
    return { watermark }
}