import { get, post, bGet, bPost } from '@/scripts/http';
import { Decrypt } from "@/scripts/utils";
import {
    GetAllRoomsParam, UserIDParam, BatchControlParam, GetRoomInfoParam, GetCMDByMIDParam, GetTerminalInfoParam, ControlParam,
    GetCtrlStateParam, ControlSwitchParam, ControlGWDeviceParam, ControlInfraredAIRParam, ControlIoTDimmerParam
} from "./type/control-type";

const ORGTOKEN = localStorage.getItem("OrgToken") ? Decrypt(localStorage.getItem("OrgToken")) : '';

export default {
    ServiceURL: `/${ORGTOKEN}/V4/Ctrl`,
    //获取所有教室列表
    async GetAllRooms(data: GetAllRoomsParam) {
        const ORGTOKEN = localStorage.getItem("OrgToken") ? Decrypt(localStorage.getItem("OrgToken")) : '';
        const res = await get(`/${ORGTOKEN}/V4/FJRH.Campus/ClsRoomService/GetAllRooms`, data, "", "json", 50000);
        return res;
    },
    //获取所有中控状态
    async AllCtrlStates() {
        const res = await get(`${this.ServiceURL}/AllCtrlStates`, {});
        return res;
    },
    //获取当前课表
    async GetCurrentDatas() {
        const res = await get(`${this.ServiceURL}/GetCurrentDatas`, {});
        return res;
    },
    //获取教室权限
    async GetClsRoomPower(data: UserIDParam) {
        const res = await get(`/${ORGTOKEN}/V4/FJRH.Campus/ClsRoomService/GetClsRoomPower`, data);
        return res;
    },
    //批量控制
    async BatchControl(data: BatchControlParam) {
        const res = await post(`${this.ServiceURL}/BatchControl`, data);
        return res;
    },
    //获取教室信息
    async GetRoomInfo(data: GetRoomInfoParam) {
        const res = await get(`/${ORGTOKEN}/V4/FJRH.Campus/ClsRoomService/GetRoomInfo`, data);
        return res;
    },
    //获取指定型号红外码
    async GetCMDByMID(data: GetCMDByMIDParam) {
        const res = await get(`${this.ServiceURL}/GetCMDByMID`, data);
        return res;
    },
    //获取指定终端运行信息
    async GetTerminalInfo(data: GetTerminalInfoParam) {
        const res = await get(`${this.ServiceURL}/GetTerminalInfo`, data);
        return res;
    },
    //单个终端控制
    async Control(data: ControlParam) {
        const res = await get(`${this.ServiceURL}/Control`, data);
        return res;
    },
    //获取指定中控状态
    async GetCtrlState(data: GetCtrlStateParam) {
        const res = await get(`${this.ServiceURL}/GetCtrlState`, data);
        return res;
    },
    //物联网管控
    async ControlSwitch(data: ControlSwitchParam) {
        const res = await get(`${this.ServiceURL}/ControlSwitch`, data);
        return res;
    },
    async ControlGWDevice(data: ControlGWDeviceParam) {
        const res = await get(`${this.ServiceURL}/ControlGWDevice`, data);
        return res;
    },
    async ControlInfraredAIR(data: ControlInfraredAIRParam) {
        const res = await get(`${this.ServiceURL}/ControlInfraredAIR`, data);
        return res;
    },
    async ControlIoTDimmer(data: ControlIoTDimmerParam) {
        const res = await get(`${this.ServiceURL}/ControlIoTDimmer`, data);
        return res;
    },
    // 获取管控组权限
    async GetCoGByUserID(data: any) {
        const res = await get(`/${ORGTOKEN}/V4/FJRH.Campus/ClsSKDService/GetCOGByUserID`, data);
        return res;
    },
    /**
     * 控制门禁新
     * @param {*} clsroomid         教室id,多个英文逗号隔开
     * @param {*} modelType         设备型号（非必填）
     */
    async GetEGuards(data: { clsroomid: string; modelType: string }) {
        const res = await get(`/${ORGTOKEN}/V4/FJRH.Campus/EntranceGuardService/GetEGuards`, data);
        return res;
    },
    /**
     * 控制门禁新
     * @param {string} id           设备id,物联网设备用，可传空(非必填)
     * @param {string} brandcode    设备品牌
     * @param {string} sNo          设备序列号
     * @param {string} ip           设备ip
     * @param {number} port         设备端口，不传使用默认端口
     * @param {number} action       操作动作，注意动作和之前的不一样了 0-开门 1-常开 2-常闭 3-常规（在线）4-关门(常闭后自动过30秒后常规)
     * @param {number} doorNum      门号
     */
    async CtrlGuard(data: { id: string; brandcode: string; sNo: string; ip: string; port: number; action: number; doorNum: number}) {
        const res = await post(`/${ORGTOKEN}/V4/FJRH.Campus/EntranceGuardService/CtrlGuard`, data);
        return res;
    },
    /**
     * 门禁告警信息分页查询
     * @param {string} roomIds      教室房间id，多个逗号隔开
     * @param {string} startTime    日志开始时间 eg:2023-04-17
     * @param {string} endTime      日志结束时间 eg:2023-05-17
     * @param {int} acDeviceType    设备类型 1:微耕/2:启卓人脸识别设备/3:海康门锁
     * @param {string} key          搜索关键字
     * @param {int} pno             页码，从1开始(必填)
     * @param {int} psize           每页大小(必填)
     * @param {string} sfield       排序字段名
     * @param {int} sdir            排序方式-1默认，0-升序，1-降序(必填)
     */
    async GetACAlarms(data: { roomIds: string; startTime: string; endTime: string; acDeviceType: 1 | 2 | 3; key: string; pno: number; psize: number; sfield: string;  sdir:0|1}) {
        const res = await get(`/${ORGTOKEN}/V4/FJRH.Campus/ACAlarmService/GetACAlarms`, data);
        return res;
    },
    /**
     * 处理门禁告警
     * @param {string} id              前面接口返回的ID
     * @param {int} solverResult       处理结果1-已解决，2-转保修
     * @param {string} solverOpinion   处理意见
     */
    async Solve(data: { id: string; solverResult: 1|2; solverOpinion: string}) {
        const res = await post(`/${ORGTOKEN}/V4/FJRH.Campus/ACAlarmService/Solve`, data);
        return res;
    },
    /**
     * 设置门锁开关
     * @param targets   网关IP和设备id的json
     * @param action    状态
     */
    async ControlGWDoor(targets:string, action: number) {
        const res = await post(`/${ORGTOKEN}/V4/FJRH.Campus/EntranceGuardService/ControlGWDoor`, {
            targets,
            action,
        });
        return res;
    },
    //门禁管控旧
    async GetGuardByCondtion(data: { clsroomid: string; modelType: string }) {
        const res = await get(`/${ORGTOKEN}/V4/EntranceGuard/GetEGuardByConditio`, data);
        return res;
        
    },
    async ControlGuard(data: any) {
        const res = await get(`/${ORGTOKEN}/V4/EntranceGuard/ControlGuard`, data);
        return res;
    },
    // 演示微耕门禁接口
    async ControlWGGuard(data: any) {
        const res = await get(`/${ORGTOKEN}/V4/FJRH.Campus/WGService/WGCtrl`, data);
        return res;
    }
}